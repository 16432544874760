(function( $ ) {
	var
	site_header = $('.site-header'),
	content_offset = $('.content-offset'),
	desktop_hamburger = $('.site-header--is-single .site-header__mobile-menu-toggle'),
	mobile_hamburger = $('.site-header__mobile-menu-toggle'),
	mobile_modal = $('.site-header__mobile-menu-modal'),
	main_navigation = $('.site-header__navigation');
	page_title = $('.site-header__page-title'),
	page_share = $('.site-header__page-share'),
	mobile_menu = $('.site-header__mobile-menu'),
	header_carousel = $('.header-carousel'),
	header_slider = $('.header-carousel__slider');

	$(window).resize(set_content_offset);
	setTimeout(function(){
		set_content_offset();
	}, 50);
	
	tippy_top();
	$(window).scroll(tippy_top);
	
	function set_content_offset() {
		var
		header_height = site_header.height();
		slider_height = (header_slider.length) ? header_slider.height() : 0;
		content_offset.css('height', header_height + slider_height + 'px');
		site_header.attr('data-header-height', header_height);
	}

	desktop_hamburger.click(function(e) {
		e.preventDefault();
		if (window.matchMedia('(min-width: 1024px)').matches) {
			main_navigation.toggleClass('nav--is-showing');
			mobile_menu.toggleClass('nav--is-showing');
			page_title.toggleClass('title--is-hidden');
			page_share.toggleClass('share--is-hidden');
		}
	});
	
	mobile_hamburger.click(function(e) {
		if (window.matchMedia('(max-width: 1024px)').matches) {
			mobile_modal.toggle();
		}
	});
	
	show_header_carousel();
	$(window).resize(show_header_carousel)
	
	var last_scroll_to_top = 0;
	$(window).scroll(function(event){
		var st = $(this).scrollTop(),
		slider_height = (header_slider.length) ? header_slider.height() : 0;
		if (st > last_scroll_to_top && st > slider_height){
			header_carousel.addClass('header-carousel--hide');
			header_carousel.removeClass('header-carousel--show');
		} else {
			header_carousel.removeClass('header-carousel--hide');
			header_carousel.addClass('header-carousel--show');
		}
		last_scroll_to_top = st;
	});
	
	function show_header_carousel() {
		if (window.matchMedia('(min-width: 768px)').matches) {
			setTimeout(function(){
				header_carousel.addClass('header-carousel--show');
				header_carousel.css('opacity', 1);
			}, 50);
		}
	}
	
	function tippy_top() {
		var scrollPos = $(window).scrollTop();
		if(scrollPos <= 0) {
			$('body').addClass('at-the-top');
		} else {
			$('body').removeClass('at-the-top');
		}
	}
	
	// admin bar help
	function adminMobileHeader() {
		$(window).scroll(function() {;
			var scrollPos = $(window).scrollTop();
			if(scrollPos > 46) {
				$('.site-header').addClass('site-header--top');
			} else {
				$('.site-header').removeClass('site-header--top');
			}
		});	
	}
	
	if ($('body').hasClass('admin-bar') ) {
		adminMobileHeader();
		$(window).resize(function(){
			adminMobileHeader();
		});
	}


})( jQuery );
